import { sortByProperty } from '@/helpers/sort';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      sort: 'asc',
      sortIndex: 0,
      headerName: 'Full Name',
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
  ];
}
